<template>
  <ul>

                                    <li v-for="option in options"
                                        :key="option.value"  class="mb-4 flex items-center justify-between">
                                        <vs-checkbox          v-model="selected"  :vs-value="option.value">{{option.value}}</vs-checkbox>
                                        <span class="ml-1" style="font-size:10px" v-show="option.count > 0">{{option.count}}</span>
                                    </li>

                                </ul>
</template>

<script>
import { mapState } from 'vuex';

// eslint-disable-next-line import/no-extraneous-dependencies
const _ = require('lodash');

export default {
  props: [
    'facet',
    'field',
  ],
  data() {
    return {
      selected: [],
      options: (this.facet.map((item) => ({
        text: `${item.value}`,
        value: item.value,
        count: item.count,
      }))).sort((a, b) => a.value - b.value),

    };
  },
  computed: {
    ...mapState([
      'filters',
    ]),
  },
  watch: {
    selected() {
      const payload = {
        facet: this.field,
        selected: this.selected,
      };
      console.log(payload);
      this.$store.dispatch('setFilter', payload);
    },
    facet() {
      _.forEach(this.options, (obj) => {
        _.set(obj, 'count', 0);
      });
      this.facet.forEach((v) => {
        this.options.find((o) => o.value === v.value).count = v.count;
      });
    },
    filters() {
      if (!this.filters[this.field]) {
        this.selected = [];
      }
    },
  },
};
</script>
<style>
  .form-group {
    margin-top: 20px;
  }
  .list-group-item {
    border: none;
  }
</style>
