<!-- =========================================================================================
  File Name: ECommerceShop.vue
  Description: eCommerce Shop Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div>


            <!-- AIS CONFIG -->



            <div  class="vx-row db-advance-search-section">


                    <div class="p-6 w-full lg:w-1/4 filter-container">
                    <vx-card>

                        <!-- MULTI RANGE -->
                        
                         <h6 class="font-bold mb-3">Study Method</h6>
                       <CheckboxFacet v-bind:facet="facets.study_method" field="study_method" />
 <vs-divider />
                        <h6 class="font-bold mb-3">Study Area</h6>
                         <CheckboxFacet v-bind:facet="facets.study_area" field="study_area" />

                        <vs-divider />

                        <!-- PRICE SLIDER -->




                        <!-- CATEGORIES -->
                       <h6 class="font-bold mb-3">Course Type</h6>
                       <CheckboxFacet v-bind:facet="facets.course_type" field="course_type" />


                        <vs-divider />

                        <!-- Brands -->
                        <h6 class="font-bold mb-4">Location</h6>

                              <CheckboxFacet v-bind:facet="facets.city" field="city" />

                        <vs-divider />

                        <!-- Rating -->
                          <h6 class="font-bold mb-3">University</h6>
                       <CheckboxFacet v-bind:facet="facets.university" field="university" />


                        <vs-divider />
                         <h6 class="font-bold mb-3">Campus</h6>
                       <CheckboxFacet v-bind:facet="facets.campus" field="campus" />


                       
</vx-card>
                    </div>


                <!-- RIGHT COL -->
                <div class="px-6 lg:w-3/4 w-full advance-search-cont">
                 <div class="text-right">
                        <!-- Stats -->

                            <p  class="font-semibold md:block hidden text-primary">
                                {{ resultsCount }} results found
                            </p>
                      </div>
                <div class="relative mb-4 mt-6">
                <vs-input style="width:100%" v-on:keyup="executeSearch" v-model="searchString"   icon-no-border placeholder="Search"  icon-pack="feather" icon="icon-search" size="large"  />
                </div>



                    <!-- SEARCH RESULT -->


                            <!-- GRID VIEW -->


                            <!-- LIST VIEW -->
                            <template >
                                <div class="items-list-view mb-base" v-for="result in results"
         :key="result.course_id">

                                    <item-list-view :item="result">

                                        <!-- SLOT: ACTION BUTTONS -->
                                        <template slot="action-buttons">
                                           
                                            <!-- <div
                                                class="item-view-secondary-action-btn bg-primary p-3 rounded-md flex flex-grow items-center justify-center text-white cursor-pointer"
                                                @click="ViewCourse(result)" style="width:150px">
                                                <span class="text-sm font-semibold ml-2" >VIEW</span>
                                            </div> -->

                                            <vs-button @click="ViewCourse(result)" class="" size="small" color="primary" type="filled">View</vs-button>

                                            <vs-button v-if="isCourseAlreadyJoined(result)" @click="viewJoinCoursefeed(result)" class="mt-3" size="small" color="primary" type="border">Join Coursefeed</vs-button>
                                            <span v-else >
                                              <span style="padding-left: 40%;" class="font-normal text-success h6 mb-0">Joined</span>                 
                                            </span>
                                        </template>
                                    </item-list-view>

                                </div>
                            </template>



                    <!-- ALGOLIA LOGO -->
                      <vs-pagination :total="getCount()"  v-model="currentPage"></vs-pagination>


                </div>
            </div>

            <!-- Join Coursefeed Popup -->
<vs-popup class="holamundo" title="Join Coursefeed" :active.sync="joinCoursefeed">
    <div class="p-5">
		<!-- <div class="vx-row text-center">
		<div class="vx-col w-full mb-4 md:mb-0 lg:mb-0">
		<div class="flex justify-center mb-4">
		</div>
		<h4>Join Coursefeed</h4>
		<p>Description text goes here</p>
		</div>
	</div>
	<hr class="re-hr"> -->

	<div class="vx-row">
	 <div class="vx-col w-full mb-5">
     <div class="jc-div">
       <h4 class="mb-2">Current Student?</h4>
       <p>Become a verified account at Account Settings page to get access.</p>
       <vs-button v-if="isUniEmailVerify" @click="submitJoinCoursefeed()" class="mt-2" size="small" color="primary" type="filled" >Join Now</vs-button> 
       <vs-button v-else class="mt-2" size="small" color="primary" type="filled" :to="'/user/settings'">Verify Now</vs-button>
     </div>
    </div>

    <div class="vx-col w-full mb-5">
     <div class="jc-div">
       <h4 class="mb-2">Future Student?</h4>
       <p>Apply through us to get admission and become part of community.</p>
       <vs-button class="mt-2" size="small" color="primary" type="filled" :to="'/my-application'">Apply Now</vs-button>
     </div>
    </div>

    <div class="vx-col w-full mb-5">
     <div class="jc-div">
       <h4 class="mb-2">Already have an offer letter?</h4>
       <p>Message us at Coursepal to get access.</p>
       <vs-button class="mt-2" size="small" color="primary" type="filled" @click="goToCoursepalChatRoom()">Message</vs-button>
       <!-- <vs-button class="mt-2" size="small" color="primary" type="filled" :to="'/chat/Coursepal'">Message</vs-button> -->
     </div>
    </div>

    <div class="vx-col w-full">
     <div class="jc-div">
       <h4 class="mb-2">Alumni?</h4>
       <p>Message us now to get access.</p>
       <vs-button class="mt-2" size="small" color="primary" type="filled" @click="goToCoursepalChatRoom()">Message</vs-button>
     </div>
    </div>
	</div>

	</div>
</vs-popup>

    </div>
</template>

<script>
import { mapState } from 'vuex';
// eslint-disable-next-line no-unused-vars
import CheckboxFacet from './CheckboxFacet.vue';
import axios from 'axios'
import { createErrorObject, createSuccessObject } from '@/utils'

export default {
  components: {
    ItemGridView: () => import("./components/ItemGridView.vue"),
    ItemListView: () => import("./components/ItemListView.vue"),
    CheckboxFacet

  },
  data() {
    return {
      currentx: 5,
      joinCoursefeed: false,
      // Filter Sidebar
      isFilterSidebarActive: true,
      clickNotClose: true,
      currentItemView: 'item-grid-view',
      selectedCourse : null
    }
  },
  computed: {
    toValue() {
      return (value, range) => [
        value.min !== null ? value.min : range.min,
        value.max !== null ? value.max : range.max,
      ]
    },
    ...mapState([
      'resultsCount',
      'results',
      'facets',
    ]),
    searchString: {
      get() {
        return this.$store.state.searchString;
      },
      set(value) {
        this.$store.dispatch('setSearchString', value);
        
        window.scrollTo(0,0);
      },
    },
    currentPage: {
      get() {
        return this.$store.state.currentPage;
      },
      set(value) {
        window.scrollTo(0,0);
        this.$store.dispatch('setCurrentPage', value);
      },
    },
    resultsPerPage: {
      get() {
        return this.$store.state.resultsPerPage;
      },
      set(value) {
        // alert(1);
        this.$store.dispatch('setResultsPerPage', value);
      },
    },

    // GRID VIEW

    windowWidth() { return this.$store.state.windowWidth },

    isUniEmailVerify () {
      return this.$store.state.auth.userData.isUniEmailVerify
    }
  },
  watch: {
    windowWidth() {
      this.setSidebarWidth()
    }
  },
  methods: {
    ViewCourse(val){
      this.$store.dispatch("setcourseUpdateId", val.course_id);
      // this.$router.push('/CourseView',params: { id: data.item.id });
      // this.$router.push({ name: 'CourseView', params: { id: val.course_id }, query:{name:val.course_name}})
      this.$router.push({ name: 'CourseView', params: { id: val.course_name }})
    },
    viewJoinCoursefeed(val){
      this.$store.dispatch("setcourseUpdateId", val.course_id);
      this.selectedCourse = val;
      this.joinCoursefeed = true;
    },
    isCourseAlreadyJoined(row){
      let courseChannels = this.$store.state.channel.courseChannels;
      const index = courseChannels.findIndex(object => Number(object.course_id) === Number(row.course_id));
      if (index === -1) {
        return true
      }else{
        return false
      }
    },
    submitJoinCoursefeed(){     
      this.$vs.loading();
      axios.post(`${process.env.VUE_APP_BASE_URL  }/getCourseDetailsByID`, {
          course_id: this.selectedCourse.course_id
        }).then(res => {
          if (res.data.data[0] !== undefined) {
              let courseDetails = res.data.data[0];
              axios.post(`${process.env.VUE_APP_BASE_URL  }/getChannelByUniversityId`, {
                university_id: courseDetails.inst_id
              }).then(res => {
                if (res.data.data[0] !== undefined) {
                  let universityChannel = res.data.data[0];
                  axios.post(`${process.env.VUE_APP_BASE_URL  }/getChannelByUniversityIdAndCourseid`, {
                    university_id: courseDetails.inst_id,
                    course_id: courseDetails.course_id
                  }).then(res => {
                    if (res.data.data[0] !== undefined) {
                      let universityCourseChannel = res.data.data[0];   
                      let promises = [];
                      promises.push(new Promise((resolve, reject) => {
                          let followUniversityChannels = [];
                          if(this.$store.state.channel.universityChannels != null){
                            followUniversityChannels = this.$store.state.channel.universityChannels;
                            const index = followUniversityChannels.findIndex(object => object.channel_id === universityChannel.channel_id);
                            if (index === -1) {
                              followUniversityChannels.push(universityChannel);
                            }
                          }else{
                            followUniversityChannels.push(universityChannel);
                          }
                          this.$store.dispatch('channel/updateFollowUniversityChannels',followUniversityChannels)
                          resolve();
                      }));
                      promises.push(new Promise((resolve, reject) => {
                          let followUniversityCourseChannel = [];
                          if(this.$store.state.channel.courseChannels != null){
                            followUniversityCourseChannel = this.$store.state.channel.courseChannels;
                            const index = followUniversityCourseChannel.findIndex(object => object.channel_id === universityCourseChannel.channel_id);
                            if (index === -1) {
                              followUniversityCourseChannel.push(universityCourseChannel);
                            }
                          }else{
                            followUniversityCourseChannel.push(universityCourseChannel);
                          }
                          this.$store.dispatch('channel/updateFollowCourseChannels',followUniversityCourseChannel)
                          resolve();
                      }));
                      Promise.all(promises).then((values) => {
                        this.$vs.loading.close();
                        this.$vs.notify(createSuccessObject('Success', 'You have joined this course'))     
                        this.joinCoursefeed = false;                   
                      });
                    }
                  })
                }
              })
          }
        }); 
    },
    getCount(){
     
        return Math.floor(this.resultsCount/7)+1;
    },
    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.isFilterSidebarActive = this.clickNotClose = false
      } else {
        this.isFilterSidebarActive = this.clickNotClose = true
      }
    },
    executeSearch() {
      this.$store.dispatch('setSearchString', this.searchString);
    },

    goToCoursepalChatRoom () {
      this.$router.push(`/chat/Coursepal-${this.$store.state.auth.userData.name}`)
    },

    // GRID VIEW - ACTIONS

  },
  mounted () {
    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true);
  },
  beforeDestroy () {
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
  },
  created() {
    this.$store.dispatch('setCurrentPage', 1);
    this.setSidebarWidth();
   this.getCount();
  }
}

</script>


<style lang="scss">
#algolia-instant-search-demo {
  .algolia-header {
    .algolia-filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #algolia-content-container {

    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .algolia-search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .algolia-price-slider {
    min-width: unset;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #algolia-instant-search-demo {
    #algolia-content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

.jc-div{
  padding: 1.5rem 3rem;
  background-color: rgba(130,134,139,.06);
  border: 1px solid #ebe9f1;
  border-radius: 0.42rem;

}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: .5rem;
    }

    .vs-sidebar--items {
      border-radius: .5rem;
    }
  }
}

@media (max-width: 992px) {
  #algolia-content-container {
    .vs-sidebar {
      position: absolute !important;
      float: none !important;
    }
  }
}

</style>

